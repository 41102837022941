import React, {useEffect, useState} from 'react';
import Watermark from '../components/watermark';
import {Translation} from 'react-i18next';
import {storeGeolocation} from '../utils/api';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

const AskGeolocation = (props) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [applicantAnswered, setApplicantAnswered] = useState(false);

  const handleSuccess = (position) => {
    storeGeolocation(position.coords.latitude, position.coords.longitude);
    setApplicantAnswered(true);
    setSuccess(true);
    setLoading(false);
  };

  const handleFailed = () => {
    setApplicantAnswered(true);
    setSuccess(false);
    setLoading(false);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleFailed);
    }
  }, []);

  if (!props.options.ask_geolocation) {
    return <Redirect to="/complete"/>;
  }

  return (
      <Translation>
        {(t) =>
            <div className="page geolocation">
              <div className="page__background">
                <Watermark/>
              </div>
              <div className="page__container">
                <section className="page__header"/>
                <section className="page__content">
                  <h1 className="step__title">{t('interface.geolocation.title')}</h1>
                  <p className="step__description">{t('interface.geolocation.description1')}<br/>{t('interface.geolocation.description2')}<br/>{t('interface.geolocation.description3')}</p>
                  {
                    !loading
                        ? success
                            ? <p className="step__message">{t('interface.geolocation.success')}</p>
                            : null
                        : null
                  }

                </section>
                <section className="page__footer">
                  {
                    !applicantAnswered ?
                        (
                            <button disabled={true}
                                    className="page__next-button">{loading ? t('interface.loading') : t('interface.next_page')}</button>
                        ) :
                        <NavLink disabled={!applicantAnswered} onClick={() => {
                          props.tracker.report('geolocation');
                        }} className="page__next-button" to="/complete">
                          {loading ? t('interface.loading') : t('interface.next_page')}
                        </NavLink>
                  }
                </section>
              </div>
            </div>
        }
      </Translation>
  );
};

const mapStateToProps = (state, props) => {

  return {
    content: state.app.content.intro,
    options: state.app.options,
  };
};

export default connect(mapStateToProps)(AskGeolocation);

